import * as React from "react"

import '../../styles/home.css'
import '../../styles/skeleton.css'
import '../../styles/normalize.css'

import Header from "../../components/Header"
import Footer from "../../components/Footer"
import Socials from "../../components/Socials"
import Meta from "../../components/Meta"
import Presentation from "../../components/Presentation"

import dd from "../../static/DayDreams.pdf"
import ddThumb from "../../images/Presentations/ddThumbnail.png"
import mono from "../../static/Monogatari.pdf"
import monoThumb from "../../images/Presentations/monogatari.png"
import alpha from "../../static/AlphaQ.pdf"
import alphaThumb from "../../images/Presentations/alphaq.jpeg"
import winuall from "../../static/Winuall.pdf"
import winThumb from "../../images/Presentations/winuall.png"
import google from "../../static/Google.pdf"
import googleThumb from "../../images/Presentations/google.png"
import faith from "../../static/Faith.pdf"
import faithThumb from "../../images/Presentations/faith.png"
import gmail from "../../static/gmail.pdf"
import gmailThumb from "../../images/Presentations/gmail.png"
import met from "../../static/Met.pdf"
import metThumb from "../../images/Presentations/met.png"

const IndexPage = () => {
    return(
        <div className="container main">
            <title>Presentations</title>
            <Meta></Meta>
            <Header></Header>
            <div className="header row">
                <div className="title-secondary twelve columns">
                    <div className="intro">
                    <h6>Beautiful visuals</h6>
                    </div>
                    <div className="headline">
                    <h1>Presentations</h1>
                    </div>
                </div>
            </div>
            <div className="ppt">
                <div className="row">
                    <Presentation title="Gmail Redesign" desc="Class Presentation, 2021" link={gmail} img={gmailThumb}></Presentation>
                    <Presentation title="The Met Redesign" desc="Class Presentation, 2021" link={met} img={metThumb}></Presentation>
                </div>
                <div className="row">
                    <Presentation title="Daydreams" desc="Code2Create 2019" link={dd} img={ddThumb}></Presentation>
                    <Presentation title="Monogatari" desc="HackInOut 2019" link={mono} img={monoThumb}></Presentation>
                </div>
                <div className="row">
                    <Presentation title="Analysis Pro" desc="Winuall Hack 2019" link={winuall} img={winThumb}></Presentation>
                    <Presentation title="org." desc="Google Internship Exercise" link={google} img={googleThumb}></Presentation>
                </div>
                <div className="row">
                    <Presentation title="Beacon" desc="HackHorizon 2019" link={alpha} img={alphaThumb}></Presentation>
                    <Presentation title="Faith" desc="Code2Create 2018" link={faith} img={faithThumb}></Presentation>
                </div>
            </div>
            <Socials></Socials>
            <Footer></Footer>
        </div>
    )
}

export default IndexPage