
import React from 'react'

const Presentation = ({link, title, desc, img}) => {
    return(
        <a href={link} className="item-work repo six columns">
            <img className="repo-header" src={img} height="160" style={{objectFit: 'cover'}}></img>
            <h1>{title}</h1>
            <h6>{desc}</h6>
        </a>
    )
}

export default Presentation